import { mapMutations } from "vuex";
import {
  dictionary,
  EDUCATION,
  MARITAL_STATUS,
  DISTRICT,
  INDUSTRY,
  PROFESSION,
  MONTHLY_INCOME,
  RELATIONSHIP,
  OTHER_RELATIONSHIP,
  BANK,
  CARD_TYPE,
  PRIMAARYID,
  PAY_ORG,
  CONVENIENT_TIME,
  LOAN_PURPOSE,
  RELIGION,
  PAY_CYCLE,
  PAY_CHL,
} from '../../utils/api'
let mixin = {
  components: {},
  data() {
    return {
      Enum: {
        educatedLoading: false,
        educated: [], // 学历
        marriedLoading: false,
        married: [], // 婚姻状况
        provLoading: false,
        prov: [], // 省市
        cityLoading: false,
        city: [], // 区
        industryLoading: false,
        industry: [], // 所属行业
        jobTypeLoading: false,
        jobType: [], // 职业类型
        monthlyIncomeLoading: false,
        monthlyIncome: [], // 平均月收入
        contactRelationLoading: false,
        contactRelation: [], // 亲属联系人
        otherContactRelationLoading: false,
        otherContactRelation: [], // 其他联系人
        bankLoading: false,
        bank: [], // 银行
        cardTypeLoading: false,
        cardType: [],
        institutionLoading: false,
        institution: [], // 机构
        timePeriodLoading: false,
        timePeriod: [], // 时间段
        loanPurposeLoading: false,
        loanPurpose: [], // 贷款目的
        docTypeLoading: false,
        docType: [], // 单据类型
        religionLoading: false,
        religion: [], // 宗教
        paydayTypeLoading: false,
        paydayType: [], // 发薪日类型
        paydayLoading: false,
        payday: [], // 发薪日
        payChlLoading: false,
        payChl: [], // 通道
      }
    }
  },
  created() {  },
  methods: {
    ...mapMutations(["SET_APPLY_ITEM"]),
    // 储存信息
    onStorageItem(key, value) {
      this.SET_APPLY_ITEM({
        key: key,
        value: value || this.form[key],
      });
    },
    // 打开弹窗
    onPopupPicker(options, callback) {
      this.$refs.popupPicker.openPicker({
        label: 'name',
        options,
        callback
      })
    },
    // 获取学历
    async GET_EDUCATION() {
      this.Enum.educatedLoading = true
      await this.getDictionary(EDUCATION).then(e => {
        this.Enum.educated = e
      })
      this.Enum.educatedLoading = false
    },
    // 获取婚姻状况
    async GET_MARITAL_STATUS() {
      this.Enum.marriedLoading = true
      await this.getDictionary(MARITAL_STATUS).then(e => {
        this.Enum.married = e
      })
      this.Enum.marriedLoading = false
    },
    // 获取地区(省市)
    async GET_DISTRICT_PROV() {
      this.Enum.provLoading = true
      await this.getDictionary(DISTRICT).then(e => {
        this.Enum.prov = e
      })
      this.Enum.provLoading = false
    },
    // 获取地区(区)
    async GET_DISTRICT_CITY(val) {
      this.Enum.cityLoading = true
      await this.getDictionary(dictionary + val).then(e => {
        this.Enum.city = e
      })
      this.Enum.cityLoading = false
    },
    // 获取行业
    async GET_INDUSTRY() {
      this.Enum.industryLoading = true
      await this.getDictionary(INDUSTRY).then(e => {
        this.Enum.industry = e
      })
      this.Enum.industryLoading = false
    },
    // 获取职务
    async GET_PROFESSION() {
      this.Enum.jobTypeLoading = true
      await this.getDictionary(PROFESSION).then(e => {
        this.Enum.jobType = e
      })
      this.Enum.jobTypeLoading = false
    },
    // 获取平均月收入
    async GET_MONTHLY_INCOME() {
      this.Enum.monthlyIncomeLoading = true
      await this.getDictionary(MONTHLY_INCOME).then(e => {
        this.Enum.monthlyIncome = e
      })
      this.Enum.monthlyIncomeLoading = false
    },
    // 亲属联系人
    async GET_RELATIONSHIP() {
      this.Enum.contactRelationLoading = true
      await this.getDictionary(RELATIONSHIP).then(e => {
        this.Enum.contactRelation = e
      })
      this.Enum.contactRelationLoading = false
    },
    // 其他联系人
    async GET_OTHER_RELATIONSHIP() {
      this.Enum.otherContactRelationLoading = true
      await this.getDictionary(OTHER_RELATIONSHIP).then(e => {
        this.Enum.otherContactRelation = e
      })
      this.Enum.otherContactRelationLoading = false
    },
    // 获取银行
    async GET_BANK() {
      this.Enum.bankLoading = true
      await this.getDictionary(BANK).then(e => {
        this.Enum.bank = e
      })
      this.Enum.bankLoading = false
    },
    // 
    async GET_CARD_TYPE() {
      this.Enum.cardTypeLoading = true
      await this.getDictionary(CARD_TYPE).then(e => {
        this.Enum.cardType = e
      })
      this.Enum.cardTypeLoading = false
    },
    // 获取机构
    async GET_INSTITUTION() {
      this.Enum.institutionLoading = true
      await this.getDictionary(PAY_ORG).then(e => {
        this.Enum.institution = e
      })
      this.Enum.institutionLoading = false
    },
    // 获取时间段
    async GET_TIME_PERIOD() {
      this.Enum.timePeriodLoading = true
      await this.getDictionary(CONVENIENT_TIME).then(e => {
        this.Enum.timePeriod = e
      })
      this.Enum.timePeriodLoading = false
    },
    // 获取贷款目的
    async GET_LOAN_PURPOSE() {
      this.Enum.loanPurposeLoading = true
      await this.getDictionary(LOAN_PURPOSE).then(e => {
        this.Enum.loanPurpose = e
      })
      this.Enum.loanPurposeLoading = false
    },
    // 获取单据类型
    async GET_DOC_TYPE() {
      this.Enum.docTypeLoading = true
      await this.getDictionary(PRIMAARYID).then(e => {
        this.Enum.docType = e
      })
      this.Enum.docTypeLoading = false
    },
    // 获取宗教
    async GET_RELIGION() {
      this.Enum.religionLoading = true
      await this.getDictionary(RELIGION).then(e => {
        this.Enum.religion = e
      })
      this.Enum.religionLoading = false
    },
    // 发薪日类型
    async GET_PAYDAY_TYPE() {
      this.Enum.paydayTypeLoading = true
      await this.getDictionary(PAY_CYCLE).then(e => {
        this.Enum.paydayType = e
      })
      this.Enum.paydayTypeLoading = false
    },
    // 获取发薪日
    GET_PAYDAY() {
      this.Enum.paydayLoading = true
      let list = []
      for (let i = 1; i <= 31; i++) {
        list.push({
          code: i,
          name: `${i}`,
        })
      }
      this.Enum.payday = list
      this.Enum.paydayLoading = false
    },
    // 通道
    async GET_PAY_CHL() {
      this.Enum.payChlLoading = true
      await this.getDictionary(PAY_CHL).then(e => {
        this.Enum.payChl = e
      })
      this.Enum.payChlLoading = false
    },
    // 获取字典
    async getDictionary(url) {
      let list = []
      await this.$axios({
        method: "get",
        url: url,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            list = e.body
          }
        })
        .catch(() => {})
      return list
    },
  }
}
export default mixin;